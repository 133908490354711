<template>
	<v-container fluid class="mx-auto">
		<v-row>
			<v-col v-if="showFilters" sm="12" md="4" lg="3" style="padding: 0; padding-right: 10px">
				<v-card width="100%" height="100%" style="padding: 10px">
					<v-row v-if="['months', 'comparison'].includes(view)">
						<v-col>
							<v-checkbox v-model="showBilling" label="Faturação" />
						</v-col>
						<v-col v-if="features.editCommissions && ['billing', 'findings'].includes(stat)">
							<v-checkbox v-model="showCommissions" :label="stat === 'billing' ? 'Comissões' : 'Total'" />
						</v-col>
					</v-row>
					<v-row v-if="['months', 'comparison'].includes(view)">
						<v-col>
							<v-checkbox
								v-if="view === 'months' && !['predicted', 'giveUps', 'margin'].includes(stat)"
								v-model="showComparativeStats"
								label="Estatísticas Comparativas"
							/>
						</v-col>
						<v-col v-if="company !== 'realtyart'">
							<v-checkbox v-if="view === 'months'" v-model="fromAllOffices" label="Grupo" />
						</v-col>
					</v-row>
					<v-btn-toggle dense mandatory v-model="activeFilter">
						<v-btn value="true" style="font-size: 0.6em">Ativos</v-btn>
						<v-btn value="" style="font-size: 0.6em">Tudo</v-btn>
						<v-btn value="false" style="font-size: 0.6em">Inativos</v-btn>
					</v-btn-toggle>
					<br /><br />
					<span v-if="groupBy === 'consultant'">
						<v-btn-toggle dense mandatory v-model="roleFilter">
							<v-btn value="!team" style="font-size: 0.6em">Consultores</v-btn>
							<v-btn value="" style="font-size: 0.6em">Tudo</v-btn>
							<v-btn value="team" style="font-size: 0.6em">Equipas</v-btn>
						</v-btn-toggle>
						<br /><br />
					</span>
					<span v-if="groupBy === 'commercialDirector'">
						<v-btn-toggle dense mandatory v-model="roleFilter">
							<v-btn value="" style="font-size: 0.6em">Tudo</v-btn>
							<v-btn value="broker,coo" style="font-size: 0.6em">Brokers</v-btn>
							<v-btn value="commercialDirector" style="font-size: 0.6em">Diretores Comerciais</v-btn>
							<v-btn value="teamLeader" style="font-size: 0.6em">Team Leaders</v-btn>
						</v-btn-toggle>
						<br /><br />
					</span>
					<span v-if="!['productivity', 'percentages'].includes(view)">
						<v-btn-toggle dense mandatory v-model="refFilter">
							<v-btn value="!Bank" style="font-size: 0.6em">Imobiliário</v-btn>
							<v-btn value="" style="font-size: 0.6em">Tudo</v-btn>
							<v-btn value="Bank" style="font-size: 0.6em">Diversos</v-btn>
						</v-btn-toggle>
						<br /><br />
					</span>
					<v-autocomplete
						v-if="!['productivity', 'percentages'].includes(view)"
						outlined
						item-value="key"
						item-text="name"
						label="Agências Atuais"
						v-model="officesFilter"
						:items="offices"
						chips
						small-chips
						multiple
						@change="handleGetIndividualStats"
					/>
					<v-autocomplete
						v-if="groupBy === 'consultant' && !['commercialDirector', 'teamLeader'].includes(user.role)"
						outlined
						item-text="name"
						label="Diretores Comerciais"
						v-model="commercialDirectorsFilter"
						:items="commercialDirectors"
						return-object
						chips
						small-chips
						multiple
						@change="handleGetIndividualStats"
					/>
					<v-autocomplete
						v-if="groupBy === 'consultant' && !['commercialDirector', 'teamLeader'].includes(user.role)"
						outlined
						item-value="key"
						item-text="name"
						label="Equipas"
						v-model="teamsFilter"
						:items="config.teams"
						chips
						small-chips
						multiple
						@change="handleGetIndividualStats"
					/>
					<v-row>
						<v-col>
							<v-text-field
								outlined
								v-model="billingMinFilter"
								type="number"
								label="Faturação Mínima"
								hide-details
							/>
						</v-col>
						<v-col>
							<v-text-field
								outlined
								v-model="billingMaxFilter"
								type="number"
								label="Faturação Máxima"
								hide-details
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field
								outlined
								v-model="commissionsMinFilter"
								type="number"
								label="Comissões Mínimas"
								hide-details
							/>
						</v-col>
						<v-col>
							<v-text-field
								outlined
								v-model="commissionsMaxFilter"
								type="number"
								label="Comissões Máximas"
								hide-details
							/>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
			<v-col sm="12" :md="showFilters ? 8 : 12" :lg="showFilters ? 9 : 12" style="padding: 0">
				<v-data-table
					:headers="headers"
					:items="individualStats"
					:expanded="expanded"
					:search="search"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:loading="loading"
					item-key="_id"
					:show-expand="!['productivity', 'percentages'].includes(view)"
					must-sort
					no-data-text="Não há resultados"
					no-results-text="Não há resultados"
					:footer-props="{
						itemsPerPageOptions: [10, 20, 50, -1],
						itemsPerPageText: 'Linhas',
						itemsPerPageAllText: 'Tudo',
						showCurrentPage: true,
						showFirstLastPage: true,
					}"
					fixed-header
					height="580px"
				>
					<template v-slot:top>
						<v-toolbar rounded>
							<v-toolbar-title>
								<div style="display: flex; gap: 10px">
									<v-select
										outlined
										dense
										v-model="groupBy"
										:items="groups"
										item-text="name"
										item-value="value"
										label="Grupo"
										hide-details
										style="min-width: 225px; max-width: 225px"
										@change="
											() => {
												role = '';
												officesFilter = [];
												commercialDirectorsFilter = [];
												teamsFilter = [];

												if (groupBy !== 'office' && stat === 'margin') {
													stat = 'billing';
												}

												if (['category', 'subCategory'].includes(groupBy) && stat !== 'expenses') {
													stat = 'expenses';
												} else if (
													!['office', 'category', 'subCategory'].includes(groupBy) &&
													stat === 'expenses'
												) {
													stat = 'billing';
												}

												handleGetIndividualStats();
											}
										"
									/>
									<v-select
										v-if="!['productivity', 'percentages'].includes(view)"
										outlined
										dense
										v-model="stat"
										:items="stats"
										item-text="name"
										item-value="value"
										label="Estatística"
										hide-details
										style="min-width: 225px; max-width: 225px"
									/>
									<v-select
										outlined
										dense
										v-model="view"
										:items="views"
										item-text="name"
										item-value="value"
										label="Visualização"
										hide-details
										style="min-width: 225px; max-width: 225px"
									/>
								</div>
							</v-toolbar-title>
							<v-spacer />
							<div v-if="['productivity', 'percentages'].includes(view)">
								<v-btn
									@click="handleBatchEdit"
									:loading="editLoading"
									color="primary"
									:disabled="!editLength"
									style="margin-right: 5px"
								>
									<v-icon left>mdi-content-save</v-icon>Guardar
								</v-btn>
							</div>
							<v-btn v-if="view !== 'goals'" icon @click="copyTable">
								<v-icon>mdi-content-copy</v-icon>
							</v-btn>
							<v-btn icon @click="showFilters = !showFilters">
								<v-icon>{{ showFilters ? "mdi-filter-outline" : "mdi-filter" }}</v-icon>
							</v-btn>
							<v-text-field
								outlined
								dense
								v-model="search"
								append-icon="mdi-magnify"
								label="Procurar"
								style="margin-right: 10px; max-width: 300px"
								hide-details
							/>
						</v-toolbar>
					</template>
					<template v-slot:item.rank="{ item }">
						<!-- FIXME: rank -->
						{{ individualStats.indexOf(item) + 1 }}
					</template>
					<template v-slot:item.name="{ item }">
						<a
							v-if="groupBy === 'consultant'"
							:href="`/users/${item._id}/stats`"
							target="_blank"
							style="text-decoration: none; color: inherit"
						>
							{{ item.name }}
						</a>
						<span v-else>{{ item.name }}</span>
						<span v-if="features.editCommissions && ['consultant'].includes(groupBy)">
							<br />
							<v-badge inline :content="`${item.commission}%`" icon="mdi-percent" color="#444" />
							<v-badge
								v-if="groupBy === 'consultant'"
								inline
								:content="`${item.bankCommission}%`"
								icon="mdi-percent"
								color="#444"
							/>
						</span>
						<v-badge
							v-if="groupBy === 'consultant'"
							inline
							:value="
								!item.commercialDirectorCommission &&
								item.manager &&
								!['coo', 'broker'].includes(item.manager.role)
							"
							icon="mdi-percent"
							color="error"
						/>
						<v-badge
							v-if="['consultant', 'commercialDirector'].includes(groupBy) && !item.active"
							inline
							dot
							:value="!item.active"
							color="error"
						/>
						<v-badge
							v-else-if="
								['consultant', 'commercialDirector'].includes(groupBy) &&
								!item.offices.some(o => selectedOffices.includes(o))
							"
							inline
							dot
							:value="!item.offices.some(o => selectedOffices.includes(o))"
							color="primary"
						/>
					</template>
					<template v-slot:item.office="{ item }">
						{{ item.offices ? allOffices.find(o => o.key === item.offices[0]).name : "" }}
					</template>
					<template :slot="`item.period.subCategoryPercent`" slot-scope="{ item }">
						{{
							item.period?.subCategoryPercent
								? `${formatNumber(item.period.subCategoryPercent, false, false, 0)}%`
								: ""
						}}
					</template>
					<template v-slot:item.category="{ item }">
						{{ item.category ? config.expenseCategories.find(c => c.key === item.category).name : "" }}
					</template>
					<template v-slot:item.startDate="{ item }">
						<td>{{ formatDate(item.startDate, "DD-MM-YYYY") }}</td>
					</template>
					<template v-slot:item.pipeline="{ item }">
						<td>{{ formatNumber(item.pipeline) }}</td>
					</template>
					<template v-if="item[year]" :slot="`item.${year}.${stat}`" slot-scope="{ item }">
						{{ stat === "findings" ? item[year][stat] : formatNumber(item[year][stat]) }}
					</template>
					<template :slot="`item.${year}.${stat}Goal`" slot-scope="{ item }">
						{{
							item[year] && item[year][`${stat}Goal`]
								? stat == "findings"
									? item[year][`${stat}Goal`]
									: formatNumber(item[year][`${stat}Goal`])
								: "Sem Objetivo"
						}}
					</template>
					<template v-if="item[year]" :slot="`item.${year}.${stat}ComparedToGoal`" slot-scope="{ item }">
						<PercentageChip
							:stats="item[year]"
							:stat="`${stat}ComparedToGoal`"
							:type="type"
							:absoluteFirst="true"
							:onClick="e => changeStatsType(e, true)"
						/>
					</template>
					<template v-if="item[year]" :slot="`item.${year}.${stat}GoalPercent`" slot-scope="{ item }">
						<span v-if="item[year][`${stat}GoalPercent`]">
							{{ formatNumber(item[year][`${stat}GoalPercent`]) }}%
							<v-progress-linear rounded :value="item[year][`${stat}GoalPercent`]" />
						</span>
					</template>
					<template v-if="item[year]" :slot="`item.${year}.${stat}Expected`" slot-scope="{ item }">
						{{
							stat == "findings"
								? formatNumber(item[year][`${stat}Expected`], false, false, 0)
								: formatNumber(item[year][`${stat}Expected`])
						}}
					</template>
					<template v-if="item[year]" :slot="`item.${year}.${stat}ComparedToExpected`" slot-scope="{ item }">
						<PercentageChip
							:stats="item[year]"
							:stat="`${stat}ComparedToExpected`"
							:type="type"
							:absoluteFirst="true"
							:onClick="e => changeStatsType(e, true)"
						/>
					</template>
					<template
						v-if="item[year]"
						:slot="`item.${year}.${stat}ComparedToExpectedPercent`"
						slot-scope="{ item }"
					>
						<PercentageChip
							:stats="item[year]"
							:stat="`${stat}ComparedToExpected`"
							:type="type"
							:onClick="e => changeStatsType(e, true)"
						/>
					</template>
					<template v-slot:item.actions="{ item }">
						<span v-if="item.goals && item.goals.find(g => g.type === stat)">
							<v-badge overlap :content="item.comments" :value="!!item.comments">
								<v-btn icon @click="openComments(item)">
									<v-icon>mdi-comment-text</v-icon>
								</v-btn>
							</v-badge>
							<v-btn
								v-if="
									groupBy !== 'team' &&
									features.editGoal &&
									dayjs(item.goals.find(g => g.type === stat && g.frequency === 'yearly').endDate).get('year') ===
										dayjs().get('year')
								"
								icon
								@click="openGoalDetail(item)"
							>
								<v-icon>mdi-pencil</v-icon>
							</v-btn>
							<template v-if="groupBy !== 'team' && features.deleteGoal">
								<span
									v-if="item.goals && item.goals.find(g => g.type === stat && g.frequency === 'yearly')?.active"
								>
									<DeletionDialog deletionText="este objetivo" :onConfirm="() => handleDeleteGoal(item)">
										<template v-slot:activator="{ onClick }">
											<v-btn icon @click="onClick">
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</template>
									</DeletionDialog>
								</span>
								<v-btn v-else icon @click.stop="handleDeleteGoal(item)">
									<v-icon>mdi-restore</v-icon>
								</v-btn>
							</template>
						</span>
						<span v-else-if="groupBy !== 'team'">
							<v-btn
								v-if="features.addOfficeGoal || features.addCommercialDirectorGoal || features.addConsultantGoal"
								icon
								@click="openGoalDetail(item)"
							>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</span>
					</template>
					<template v-for="i in months" :slot="`item.${i}-${year}`" slot-scope="{ item }">
						<span :key="i">
							<span :style="calculateColor(item[`${i}-${year}`], `${i}-${year}`, stat)">
								{{ formatNumber(showBilling && item[`${i}-${year}`] && item[`${i}-${year}`][stat]) }}
							</span>
							<br v-if="showComparativeStats" />
							<span v-if="showComparativeStats" style="display: flex; gap: 5px; float: right">
								<PercentageChip
									:stats="item[`${i}-${year}`]"
									:stat="`${stat}YearlyGrowth`"
									tooltip="Comparado ao mesmo mês do ano anterior"
									:type="type"
									:onClick="changeStatsType"
								/>
								<PercentageChip
									:stats="item[`${i}-${year}`]"
									:stat="`${stat}PeriodicGrowth`"
									tooltip="Comparado ao mês anterior"
									:type="type"
									:onClick="changeStatsType"
								/>
								<PercentageChip
									:stats="item[`${i}-${year}`]"
									:stat="`${stat}ComparedToMedian`"
									tooltip="Comparado à mediana"
									:type="type"
									:onClick="changeStatsType"
								/>
							</span>
							<br />
							{{
								showCommissions
									? formatNumber(
											item[`${i}-${year}`] &&
												item[`${i}-${year}`][stat === "findings" ? "findingsValue" : "commissions"],
									  )
									: ""
							}}
							<PercentageChip
								:stats="item[`${i}-${year}`]"
								:stat="stat"
								tooltip="Margem"
								:type="0"
								:onClick="() => {}"
							/>
						</span>
					</template>
					<template v-for="i in ['lastPeriod', 'period']" :slot="`item.${i}`" slot-scope="{ item }">
						<span v-if="item[i]" :key="i">
							<span :style="calculateColor(item[i], i, stat)">
								{{ formatNumber(showBilling && item[i] && item[i][stat]) }}
							</span>
							<br />
							{{
								showCommissions
									? formatNumber(item[i] && item[i][stat === "findings" ? "findingsValue" : "commissions"])
									: ""
							}}
							<PercentageChip :stats="item[i]" :stat="stat" tooltip="Margem" :type="0" :onClick="() => {}" />
						</span>
					</template>
					<template
						:slot="`item.${monthAndYear}.${stat}YearlyGrowth${translateStatsType()}`"
						slot-scope="{ item }"
					>
						<PercentageChip
							:stats="item[monthAndYear]"
							:stat="`${stat}YearlyGrowth`"
							tooltip="Comparado ao mesmo mês do ano anterior"
							:type="type"
							:onClick="changeStatsType"
						/>
					</template>
					<template
						:slot="`item.${monthAndYear}.${stat}PeriodicGrowth${translateStatsType()}`"
						slot-scope="{ item }"
					>
						<PercentageChip
							:stats="item[monthAndYear]"
							:stat="`${stat}PeriodicGrowth`"
							tooltip="Comparado ao mês anterior"
							:type="type"
							:onClick="changeStatsType"
						/>
					</template>
					<template
						:slot="`item.${monthAndYear}.${stat}ComparedToMedian${translateStatsType()}`"
						slot-scope="{ item }"
					>
						<PercentageChip
							:stats="item[monthAndYear]"
							:stat="`${stat}ComparedToMedian`"
							tooltip="Comparado à mediana"
							:type="type"
							:onClick="changeStatsType"
						/>
					</template>
					<template :slot="`item.${year}.${stat}PeriodicGrowth${translateStatsType()}`" slot-scope="{ item }">
						<PercentageChip
							:stats="item[year]"
							:stat="`${stat}PeriodicGrowth`"
							tooltip="Comparado ao ano anterior"
							:type="type"
							:onClick="changeStatsType"
						/>
					</template>
					<template :slot="`item.${year}.${stat}ComparedToMedian${translateStatsType()}`" slot-scope="{ item }">
						<PercentageChip
							:stats="item[year]"
							:stat="`${stat}ComparedToMedian`"
							tooltip="Comparado à mediana"
							:type="type"
							:onClick="changeStatsType"
						/>
					</template>
					<template
						v-for="period in [monthAndYear, year]"
						:slot="`item.${period}.${stat}PercentOfTotal`"
						slot-scope="{ item }"
					>
						{{
							item[period] && item[period][`${stat}PercentOfTotal`]
								? item[period][`${stat}PercentOfTotal`].toFixed(1)
								: 0
						}}%
					</template>
					<template
						v-for="period in [monthAndYear, year]"
						:slot="`item.${period}.${stat}TransactionHighest`"
						slot-scope="{ item }"
					>
						{{ item[period] && formatNumber(item[period][`${stat}TransactionHighest`]) }}
					</template>
					<template
						v-for="period in [monthAndYear, year]"
						:slot="`item.${period}.${stat}TransactionLowest`"
						slot-scope="{ item }"
					>
						{{ item[period] && formatNumber(item[period][`${stat}TransactionLowest`]) }}
					</template>
					<template
						v-for="period in [monthAndYear, year]"
						:slot="`item.${period}.${stat}TransactionCount`"
						slot-scope="{ item }"
					>
						{{ item[period] && item[period][`${stat}TransactionCount`] }}
					</template>
					<template
						v-for="period in [monthAndYear, year]"
						:slot="`item.${period}.${stat}TransactionAverage`"
						slot-scope="{ item }"
					>
						{{ item[period] && formatNumber(item[period][`${stat}TransactionAverage`]) }}
					</template>
					<template
						v-for="period in [monthAndYear, year]"
						:slot="`item.${period}.${stat}TransactionMedian`"
						slot-scope="{ item }"
					>
						{{ item[period] && formatNumber(item[period][`${stat}TransactionMedian`]) }}
					</template>
					<template
						v-for="period in [monthAndYear, year]"
						:slot="`item.${period}.${stat}TransactionLastDate`"
						slot-scope="{ item }"
					>
						{{ item[period] && formatDate(item[period][`${stat}TransactionLastDate`], "DD-MMM-YY") }}
					</template>
					<template v-if="item[year]" :slot="`item.total.${stat}`" slot-scope="{ item }">
						{{ item.total ? formatNumber(item.total[stat]) : 0 }}
						<v-badge
							v-if="item.total"
							inline
							:value="item.total[stat] > config.billingLimit && item.commission < config.billingLimitPercentage"
							icon="mdi-percent"
							color="error"
						/>
					</template>
					<template v-if="item[year]" :slot="`item.${year}.creditValue`" slot-scope="{ item }">
						{{ formatNumber(item[year].creditValue) }}
					</template>
					<template
						v-if="!['comparativeStats', 'otherStats', 'productivity', 'percentages'].includes(view)"
						slot="body.append"
					>
						<td :colspan="['office', 'category', 'subCategory'].includes(groupBy) ? 1 : 2" />
						<td v-if="selectedOffices.length > 1 && groupBy !== 'office'" />
						<td v-if="groupBy === 'subCategory'" />
						<td style="text-align: center; padding-left: 15px; font-size: 1.5em">Total</td>
						<td v-if="!['findings', 'expenses', 'margin'].includes(stat)">
							<span :style="calculateColor(total.pipeline, 'total', 'pipeline')">
								{{ formatNumber(total.pipeline) }}
							</span>
						</td>
						<td v-if="view === 'goals'">{{ formatNumber(total[year]?.[`${stat}Goal`]) }}</td>
						<td v-if="view === 'goals'">{{ formatNumber(total[year]?.[`${stat}Expected`]) }}</td>
						<td v-if="view === 'goals'">{{ formatNumber(total[year]?.[stat]) }}</td>
						<td v-if="view === 'goals'">{{ formatNumber(total[year]?.[`${stat}ComparedToExpected`]) }}</td>
						<td v-if="view === 'goals'">{{ formatNumber(total[year]?.[`${stat}ComparedToExpectedPercent`]) }}%</td>
						<td v-if="view === 'goals'">{{ formatNumber(total[year]?.[`${stat}GoalPercent`]) }}%</td>
						<td v-if="view === 'goals'">{{ formatNumber(total[year]?.[`${stat}ComparedToGoal`]) }}</td>
						<td
							v-else
							v-for="i in view === 'comparison' ? ['lastPeriod', 'period'] : months"
							:key="i"
							style="text-align: right; padding-right: 15px; text-wrap: nowrap"
						>
							<span
								:style="
									calculateColor(
										total[view === 'comparison' ? i : `${i}-${year}`],
										view === 'comparison' ? i : `${i}-${year}`,
										stat,
									)
								"
							>
								{{
									formatNumber(
										showBilling &&
											total[view === "comparison" ? i : `${i}-${year}`] &&
											total[view === "comparison" ? i : `${i}-${year}`][stat],
									)
								}}
							</span>
							<br v-if="showComparativeStats || stat === 'margin'" />
							<PercentageChip
								v-if="stat === 'margin'"
								:stats="total[view === 'comparison' ? i : `${i}-${year}`]"
								:stat="stat"
								tooltip="Margem"
								:type="0"
								:onClick="() => {}"
							/>
							<span v-if="showComparativeStats" style="display: flex; gap: 5px; float: right">
								<PercentageChip
									:stats="total[view === 'comparison' ? i : `${i}-${year}`]"
									:stat="`${stat}YearlyGrowth`"
									tooltip="Comparado ao mesmo mês do ano anterior"
									:type="type"
									:onClick="changeStatsType"
								/>
								<PercentageChip
									:stats="total[view === 'comparison' ? i : `${i}-${year}`]"
									:stat="`${stat}PeriodicGrowth`"
									tooltip="Comparado ao mês anterior"
									:type="type"
									:onClick="changeStatsType"
								/>
							</span>
							<br />
							{{
								showCommissions
									? formatNumber(
											total[view === "comparison" ? i : `${i}-${year}`] &&
												total[view === "comparison" ? i : `${i}-${year}`][
													stat === "findings" ? "findingsValue" : "commissions"
												],
									  )
									: ""
							}}
						</td>
						<td v-if="!['comparison', 'goals'].includes(view)" style="text-align: right; padding-right: 15px">
							<span :style="calculateColor(total.period, 'period', stat)">
								{{ formatNumber(showBilling && total.period && total.period[stat]) }}
							</span>
							<br />
							{{
								showCommissions
									? formatNumber(
											total.period && total.period[stat === "findings" ? "findingsValue" : "commissions"],
									  )
									: ""
							}}
							<PercentageChip
								v-if="stat === 'margin'"
								:stats="total.period"
								:stat="stat"
								tooltip="Margem"
								:type="0"
								:onClick="() => {}"
							/>
						</td>
						<td />
					</template>
					<template v-else-if="view === 'productivity'" slot="body.append">
						<td v-if="selectedOffices.length > 1 && groupBy !== 'office'" />
						<td />
						<td>{{ (calculateKeyProductivity("hasReservation") * 100).toFixed(2) }}%</td>
						<td>{{ (calculateKeyProductivity("hasTransaction") * 100).toFixed(2) }}%</td>
						<td>{{ (calculateKeyProductivity("isFinder") * 100).toFixed(2) }}%</td>
						<td>{{ (calculateKeyProductivity("hasReference") * 100).toFixed(2) }}%</td>
						<td>{{ (calculateTotalProductivity() * 100).toFixed(2) }}%</td>
					</template>
					<template v-slot:expanded-item="{ headers, item }">
						<td />
						<td align="left">{{ groupBy === "consultant" && item.commission ? `${item.commission}%` : "" }}</td>
						<td v-if="selectedOffices.length > 1" />
						<td align="center">{{ groupBy === "consultant" && item.manager ? item.manager.name : "" }}</td>
						<td :colspan="view === 'goals' ? 10 : months.length + 3" />
					</template>
					<template v-if="view === 'percentages'" slot="body.prepend">
						<td colspan="4" />
						<td>
							<v-combobox
								outlined
								dense
								:value="batchCommission"
								@change="value => changeAllCommissions('commission', value)"
								:items="[40, 47.8, 50, 57.3, 60]"
								label="Comissão"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px"
							/>
						</td>
						<td>
							<v-combobox
								outlined
								dense
								:value="batchBankCommission"
								@change="value => changeAllCommissions('bankCommission', value)"
								:items="[20, 50, 60]"
								label="Comissão Bancária"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchCommercialDirectorCommission"
								@change="value => changeAllCommissions('commercialDirectorCommission', value)"
								label="Comissão do Responsável"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchCommercialDirectorBankCommission"
								@change="value => changeAllCommissions('commercialDirectorBankCommission', value)"
								label="Comissão Bancária do Responsável"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchBrokerCommission"
								@change="value => changeAllCommissions('brokerCommission', value)"
								label="Comissão do Broker"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px; width: 215px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchCooCommission"
								@change="value => changeAllCommissions('cooCommission', value)"
								label="Comissão do COO"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px; width: 215px"
							/>
						</td>
						<td>
							<v-text-field
								outlined
								dense
								:value="batchCreditManagerCommission"
								@change="value => changeAllCommissions('creditManagerCommission', value)"
								label="Comissão da Gestora de Crédito"
								append-icon="mdi-percent"
								type="number"
								style="margin: 15px; width: 215px"
							/>
						</td>
					</template>
					<template v-slot:item.commission="{ headers, item }">
						<v-combobox
							outlined
							dense
							:value="item.commission"
							@change="value => changeCommission(item, 'commission', value)"
							:items="[40, 47.8, 50, 57.3, 60]"
							label="Comissão"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px"
						/>
					</template>
					<template v-slot:item.bankCommission="{ headers, item }">
						<v-combobox
							outlined
							dense
							:value="item.bankCommission"
							@change="value => changeCommission(item, 'bankCommission', value)"
							:items="[20, 50, 60]"
							label="Comissão Bancária"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px"
						/>
					</template>
					<template v-slot:item.commercialDirectorCommission="{ headers, item }">
						<v-text-field
							outlined
							dense
							:value="item.commercialDirectorCommission"
							@change="value => changeCommission(item, 'commercialDirectorCommission', value)"
							label="Comissão do Responsável"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px"
						/>
					</template>
					<template v-slot:item.commercialDirectorBankCommission="{ headers, item }">
						<v-text-field
							outlined
							dense
							:value="item.commercialDirectorBankCommission"
							@change="value => changeCommission(item, 'commercialDirectorBankCommission', value)"
							label="Comissão Bancária do Responsável"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px"
						/>
					</template>
					<template v-slot:item.brokerCommission="{ headers, item }">
						<v-text-field
							outlined
							dense
							:value="item.brokerCommission"
							@change="value => changeCommission(item, 'brokerCommission', value)"
							label="Comissão do Broker"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 215px"
						/>
					</template>
					<template v-slot:item.cooCommission="{ headers, item }">
						<v-text-field
							outlined
							dense
							:value="item.cooCommission"
							@change="value => changeCommission(item, 'cooCommission', value)"
							label="Comissão do COO"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 215px"
						/>
					</template>
					<template v-slot:item.creditManagerCommission="{ headers, item }">
						<v-text-field
							outlined
							dense
							:value="item.creditManagerCommission"
							@change="value => changeCommission(item, 'creditManagerCommission', value)"
							label="Comissão da Gestora de Crédito"
							append-icon="mdi-percent"
							type="number"
							style="top: 15px; width: 215px"
						/>
					</template>
					<template v-slot:item.hasReservation="{ headers, item }">
						<v-checkbox
							outlined
							:input-value="getProductivityKey(item, 'hasReservation')"
							@change="value => changeProductivity(item, 'hasReservation', value)"
						/>
					</template>
					<template v-slot:item.hasTransaction="{ headers, item }">
						<v-checkbox
							outlined
							:input-value="getProductivityKey(item, 'hasTransaction')"
							@change="value => changeProductivity(item, 'hasTransaction', value)"
						/>
					</template>
					<template v-slot:item.isFinder="{ headers, item }">
						<v-checkbox
							outlined
							:input-value="getProductivityKey(item, 'isFinder')"
							@change="value => changeProductivity(item, 'isFinder', value)"
						/>
					</template>
					<template v-slot:item.hasReference="{ headers, item }">
						<v-checkbox
							outlined
							:input-value="getProductivityKey(item, 'hasReference')"
							@change="value => changeProductivity(item, 'hasReference', value)"
						/>
					</template>
					<template v-slot:item.productivity="{ headers, item }">
						<v-chip :color="calculateProductivity(item) ? 'success' : 'error'">
							{{ calculateProductivity(item) * 100 }}%
							<v-icon right>
								{{ calculateProductivity(item) ? "mdi-checkbox-marked-circle" : "mdi-close-circle" }}
							</v-icon>
						</v-chip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-dialog v-if="selectedTarget" v-model="showGoalDetail" width="500px">
			<GoalDetail
				:overwriteGroup="groupBy"
				:overwriteTarget="selectedTarget._id || selectedTarget"
				:overwriteType="stat"
				:goals="selectedGoals"
				:onClose="closeGoalDetail"
			/>
		</v-dialog>
		<v-dialog v-if="selectedTarget" v-model="showComments" width="550px">
			<Comments
				group="users"
				:id="selectedTarget._id || selectedTarget"
				:onSubmit="onCommentSubmit"
				:types="[
					{ name: 'Diretor Comercial', value: 'commercialDirector' },
					{ name: 'Broker', value: 'broker' },
				]"
			/>
		</v-dialog>
	</v-container>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import chroma from "chroma-js";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";

import PercentageChip from "./PercentageChip";
import GoalDetail from "../GoalDetail";
import Comments from "../Comments.vue";
import DeletionDialog from "../DeletionDialog.vue";

import { getGroupedStats } from "../../api/stats";
import { getUsers, patchUsers } from "../../api/users";
import { patchGoal, deleteGoal } from "../../api/goals";

import { formatDate, formatNumber } from "../../utils/utils";

export default Vue.extend({
	name: "IndividualYearlyTable",
	props: ["fields"],
	components: {
		PercentageChip,
		GoalDetail,
		Comments,
		DeletionDialog,
	},
	async created() {
		this.formatHeaders();

		this.setupSelectBoxes();

		if (["commercialDirector", "teamLeader"].includes(this.user.role)) {
			this.commercialDirectorsFilter = [this.user];
		}

		this.handleGetCommercialDirectors();
	},
	watch: {
		selectedOffices() {
			this.handleGetIndividualStats();
		},
		dateRange() {
			this.handleGetIndividualStats();

			this.productivityEdit = {};
			this.editLength = 0;
		},
		showComparativeStats() {
			this.formatHeaders();
		},
		fromAllOffices: function () {
			this.handleGetIndividualStats();
		},
		activeFilter() {
			this.handleGetIndividualStats();
		},
		roleFilter() {
			this.handleGetIndividualStats();
		},
		refFilter() {
			this.handleGetIndividualStats();
		},
		billingMinFilter() {
			this.handleGetIndividualStats();
		},
		billingMaxFilter() {
			this.handleGetIndividualStats();
		},
		commissionsMinFilter() {
			this.handleGetIndividualStats();
		},
		commissionsMaxFilter() {
			this.handleGetIndividualStats();
		},
		stat() {
			if (this.stat === "margin") {
				this.groupBy = "office";
			} else if (!["category", "subCategory"].includes(this.groupBy) && this.stat === "expenses") {
				this.groupBy = "office";
			} else if (["category", "subCategory"].includes(this.groupBy) && this.stat !== "expenses") {
				this.groupBy = "office";
			}

			this.handleGetIndividualStats();

			this.showCommissions = this.stat === "findings";

			this.setupSelectBoxes();
		},
		view() {
			this.commissionsEdit = {};
			this.productivityEdit = {};
			this.showBilling = true;
			this.fromAllOffices = false;
			this.showComparativeStats = false;

			if (this.view === "goals") {
				if (!["billing", "reservations", "findings"].includes(this.stat)) this.stat = "billing";

				this.handleGetIndividualStats();
			} else if (
				["productivity", "percentages"].includes(this.view) &&
				!["consultant", "commercialDirector"].includes(this.groupBy)
			) {
				this.groupBy = "consultant";

				this.handleGetIndividualStats();
			} else {
				if (this.view === "comparativeStats" && ["predicted", "giveUps"].includes(this.stat)) {
					this.stat = "billing";
				}

				if (["otherStats", "percentages"].includes(this.view)) this.stat = "billing";

				this.formatHeaders();
			}

			this.setupSelectBoxes();
		},
		type() {
			this.formatHeaders();
		},
	},
	computed: {
		activeFilter: {
			get() {
				return this.active;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.active = val;
				}, 500);
			},
		},
		roleFilter: {
			get() {
				return this.role;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.role = val;
				}, 500);
			},
		},
		refFilter: {
			get() {
				return this.ref;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.ref = val;
				}, 500);
			},
		},
		billingMinFilter: {
			get() {
				return this.billingMin;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.billingMin = val;
				}, 1000);
			},
		},
		billingMaxFilter: {
			get() {
				return this.billingMax;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.billingMax = val;
				}, 1000);
			},
		},
		commissionsMinFilter: {
			get() {
				return this.commissionsMin;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.commissionsMin = val;
				}, 1000);
			},
		},
		commissionsMaxFilter: {
			get() {
				return this.commissionsMax;
			},
			set(val) {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.commissionsMax = val;
				}, 1000);
			},
		},
		...mapState([
			"company",
			"user",
			"features",
			"allOffices",
			"offices",
			"selectedOffices",
			"dateRange",
			"config",
		]),
	},
	data() {
		return {
			loading: false,
			headers: [],
			search: "",
			sortBy: "period",
			sortDesc: true,
			expanded: [],

			showFilters: false,
			showBilling: true,
			showCommissions: false,
			showComparativeStats: false,
			fromAllOffices: false,
			active: "true",
			role: "",
			ref: "",
			officesFilter: [],
			commercialDirectors: [],
			commercialDirectorsFilter: [],
			teamsFilter: [],
			billingMin: 0,
			billingMax: 0,
			commissionsMin: 0,
			commissionsMax: 0,

			groups: [],
			groupBy: "",
			stats: [],
			stat: "",
			views: [],
			view: "",
			firstMonth: null,
			lastMonth: null,
			year: null,
			monthAndYear: null,
			months: [],
			type: 0,

			individualStats: [],
			total: {},

			scale: chroma.scale(["red", "orange", "goldenrod", "green"]),

			batchCommission: null,
			batchBankCommission: null,
			batchCommercialDirectorCommission: null,
			batchCommercialDirectorBankCommission: null,
			batchBrokerCommission: null,
			batchCooCommission: null,
			batchCreditManagerCommission: null,
			commissionsEdit: {},
			editLength: 0,
			editLoading: false,

			productivityEdit: {},

			showGoalDetail: false,
			showComments: false,
			selectedGoals: null,
			selectedTarget: null,

			timeout: null,
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		dayjs,
		formatDate,
		formatNumber,
		patchGoal,
		billingSort(a, b) {
			return (a ? a[this.stat] : 0) > (b ? b[this.stat] : 0) ? 1 : -1;
		},
		debounce(fn, delay) {
			var timeoutID = null;
			return function () {
				clearTimeout(timeoutID);
				var args = arguments;
				var that = this;
				timeoutID = setTimeout(function () {
					fn.apply(that, args);
				}, delay);
			};
		},
		changeStatsType(e, skipLast) {
			this.type = this.type === 2 || (this.type === 1 && skipLast) ? 0 : this.type + 1;
		},
		translateStatsType() {
			return this.type === 2 ? "Last" : this.type === 1 ? "" : "Percent";
		},
		formatHeaders() {
			this.firstMonth = Number(this.dateRange[0].split("-")[1]);
			this.lastMonth = Number(this.dateRange[1].split("-")[1]);
			this.year = Number(this.dateRange[1].split("-")[0]);
			this.monthAndYear = `${this.lastMonth}-${this.year}`;
			const thisMonthAndYearText = formatDate(
				dayjs(this.dateRange[1]).set("month", this.lastMonth - 1),
				"MMMM YYYY",
			);

			if (this.view === "percentages") {
				this.headers = [
					{ text: "Nome", value: "name", width: 175 },
					{ text: "Diretor Comercial", value: "manager.name", width: 175 },
					{ text: "Faturação desde sempre", value: `total.${this.stat}`, align: "right", width: 150 },
					{ text: "Financiamento", value: `${this.year}.creditValue`, align: "right", width: 150 },
					{ text: "Comissão", value: "commission", width: 165 },
					{ text: "Comissão Bancária", value: "bankCommission", width: 165 },
					{ text: "Comissão do Responsável", value: "commercialDirectorCommission", width: 205 },
					{ text: "Comissão Bancária do Responsável", value: "commercialDirectorBankCommission", width: 260 },
					{ text: "Comissão do Broker", value: "brokerCommission", width: "100%" },
					{ text: "Comissão do COO", value: "cooCommission", width: "100%" },
					{ text: "Comissão da Gestora de Crédito", value: "creditManagerCommission", width: "100%" },
				];

				this.sortBy = `total.${this.stat}`;
			} else if (this.view === "productivity") {
				this.headers = [
					{ text: "Nome", value: "name", width: 175 },
					{ text: "Pipeline", value: "hasReservation", width: 80 },
					{ text: "Transações", value: "hasTransaction", width: 80 },
					{ text: "Angariações", value: "isFinder", width: 80 },
					{ text: "Referências", value: "hasReference", width: 80 },
					{ text: "Productividade", value: "productivity", align: "center", width: 80 },
					{ text: "", value: "", width: "100%" },
				];

				if (this.selectedOffices.length > 1 && this.groupBy !== "office") {
					this.headers.splice(0, 0, { text: "Agência", value: "office", width: 100 });
				} else {
					this.headers = this.headers.filter(h => h.value !== "office");
				}
			} else {
				this.headers = [
					{ text: "#", value: "rank", width: 60 },
					{ text: "Nome", value: "name", width: 175 },
				];

				if (!["office", "category", "subCategory"].includes(this.groupBy)) {
					this.headers.push({ text: "Data de Entrada", value: "startDate", width: 150 });
				}

				if (!["findings", "expenses", "margin"].includes(this.stat)) {
					this.headers.push({ text: "Em Pipeline", value: "pipeline", width: 120 });
				}

				if (this.view === "comparison") {
					this.headers.push({
						text: `${formatDate(`${this.firstMonth}-${this.year - 1}`, "MMM-YY", false, "M-YYYY")} ➔ ${formatDate(
							`${this.lastMonth}-${this.year - 1}`,
							"MMM-YY",
							false,
							"M-YYYY",
						)}`,
						value: "lastPeriod",
						align: "right",
						width: this.showComparativeStats ? 250 : 140,
						sort: this.billingSort,
					});
					this.headers.push({
						text: `${formatDate(`${this.firstMonth}-${this.year}`, "MMM-YY", false, "M-YYYY")} ➔ ${formatDate(
							`${this.lastMonth}-${this.year}`,
							"MMM-YY",
							false,
							"M-YYYY",
						)}`,
						value: "period",
						align: "right",
						width: this.showComparativeStats ? 250 : 140,
						sort: this.billingSort,
					});

					this.sortBy = "period";
				} else if (this.view === "goals") {
					this.headers = this.headers.concat([
						{ text: "Objetivo", value: `${this.year}.${this.stat}Goal`, align: "right", width: 130 },
						{ text: "Esperado", value: `${this.year}.${this.stat}Expected`, align: "right", width: 130 },
						{ text: "Realizado", value: `${this.year}.${this.stat}`, align: "right", width: 130 },
						{
							text: "Diferença do Esperado",
							value: `${this.year}.${this.stat}ComparedToExpected`,
							align: "right",
							width: 160,
						},
						{
							text: "Diferença do Esperado %",
							value: `${this.year}.${this.stat}ComparedToExpectedPercent`,
							align: "right",
							width: 130,
						},
						{
							text: "% do Objetivo",
							value: `${this.year}.${this.stat}GoalPercent`,
							align: "right",
							width: 130,
						},
						{
							text: "Diferença do Objetivo",
							value: `${this.year}.${this.stat}ComparedToGoal`,
							align: "right",
							width: 140,
						},
						{ text: "Ações", value: "actions", align: "center", width: 140 },
					]);

					this.sortBy = `${this.year}.${this.stat}`;
				} else if (this.view === "comparativeStats") {
					this.headers = this.headers.concat([
						{
							text: thisMonthAndYearText,
							value: this.monthAndYear,
							align: "right",
							width: 140,
							sort: this.billingSort,
						},
						{
							text: "Comparado ao mesmo mês do ano anterior",
							value: `${this.monthAndYear}.${this.stat}YearlyGrowth${this.translateStatsType()}`,
							align: "right",
							width: 150,
						},
						{
							text: "Comparado ao mês anterior",
							value: `${this.monthAndYear}.${this.stat}PeriodicGrowth${this.translateStatsType()}`,
							align: "right",
							width: 150,
						},
						{
							text: "Comparado à mediana",
							value: `${this.monthAndYear}.${this.stat}ComparedToMedian${this.translateStatsType()}`,
							align: "right",
							width: 130,
						},
						{
							text: `Percentagem do total (${thisMonthAndYearText})`,
							value: `${this.monthAndYear}.${this.stat}PercentOfTotal`,
							align: "right",
							width: 130,
						},
						{ text: this.year, value: `${this.year}.${this.stat}`, align: "right", width: 130 },
						{
							text: "Comparado ao ano anterior",
							value: `${this.year}.${this.stat}PeriodicGrowth${this.translateStatsType()}`,
							align: "right",
							width: 130,
						},
						{
							text: `Comparado à mediana (${this.year})`,
							value: `${this.year}.${this.stat}ComparedToMedian${this.translateStatsType()}`,
							align: "right",
							width: 130,
						},
						{
							text: `Percentagem do total (${this.year})`,
							value: `${this.year}.${this.stat}PercentOfTotal`,
							align: "right",
							width: 130,
						},
					]);

					this.sortBy = `${this.year}.${this.stat}`;
				} else if (this.view === "otherStats") {
					this.headers = this.headers.concat([
						{
							text: thisMonthAndYearText,
							value: this.monthAndYear,
							align: "right",
							width: 140,
							sort: this.billingSort,
						},
						{
							text: `Fatura mais alta (${thisMonthAndYearText})`,
							value: `${this.monthAndYear}.${this.stat}TransactionHighest`,
							align: "right",
							width: 130,
						},
						{
							text: `Fatura mais baixa (${thisMonthAndYearText})`,
							value: `${this.monthAndYear}.${this.stat}TransactionLowest`,
							align: "right",
							width: 130,
						},
						{
							text: `Nº de Faturas (${thisMonthAndYearText})`,
							value: `${this.monthAndYear}.${this.stat}TransactionCount`,
							align: "right",
							width: 80,
						},
						{
							text: `Fatura média (${thisMonthAndYearText})`,
							value: `${this.monthAndYear}.${this.stat}TransactionAverage`,
							align: "right",
							width: 130,
						},
						{
							text: `Fatura mediana (${thisMonthAndYearText})`,
							value: `${this.monthAndYear}.${this.stat}TransactionMedian`,
							align: "right",
							width: 130,
						},
						{
							text: `Última Fatura (${thisMonthAndYearText})`,
							value: `${this.monthAndYear}.${this.stat}TransactionLastDate`,

							align: "center",
							width: 100,
						},
						{ text: this.year, value: `${this.year}.${this.stat}`, align: "right", width: 130 },
						{
							text: `Fatura mais alta (${this.year})`,
							value: `${this.year}.${this.stat}TransactionHighest`,
							align: "right",
							width: 130,
						},
						{
							text: `Fatura mais baixa (${this.year})`,
							value: `${this.year}.${this.stat}TransactionLowest`,
							align: "right",
							width: 130,
						},
						{
							text: `Nº de Faturas (${this.year})`,
							value: `${this.year}.${this.stat}TransactionCount`,
							align: "right",
							width: 80,
						},
						{
							text: `Fatura média (${this.year})`,
							value: `${this.year}.${this.stat}TransactionAverage`,
							align: "right",
							width: 130,
						},
						{
							text: `Fatura mediana (${this.year})`,
							value: `${this.year}.${this.stat}TransactionMedian`,
							align: "right",
							width: 130,
						},
						{
							text: `Última Fatura (${this.year})`,
							value: `${this.year}.${this.stat}TransactionLastDate`,
							align: "center",
							width: 100,
						},
					]);

					this.sortBy = `${this.year}.${this.stat}`;
				} else {
					this.months = [];

					for (let i = this.firstMonth; i <= this.lastMonth; i++) {
						if (this.total[`${i}-${this.year}`]) {
							this.months.push(i);
							this.headers.push({
								text: formatDate(`${i}-${this.year}`, "MMMM", false, "M-YYYY"),
								value: `${i}-${this.year}`,
								align: "right",
								width: this.showComparativeStats ? 250 : 105,
								sort: this.billingSort,
							});
						}
					}

					this.headers.push({
						text: "Total",
						value: "period",
						align: "right",
						width: this.showComparativeStats ? 250 : 120,
						sort: this.billingSort,
					});

					this.sortBy = "period";
				}

				this.headers.push({ text: "", value: "data-table-expand", width: "100%" });

				if (this.groupBy === "subCategory") {
					this.headers.splice(2, 0, { text: "%", value: "period.subCategoryPercent", width: 100 });
					this.headers.splice(2, 0, { text: "Categoria", value: "category", width: 135 });
				}

				if (
					(this.selectedOffices.length > 1 || this.fromAllOffices) &&
					!["office", "subCategory"].includes(this.groupBy)
				) {
					this.headers.splice(2, 0, { text: "Agência", value: "office", width: 100 });
				} else {
					this.headers = this.headers.filter(h => h.value !== "office");
				}
			}
		},
		setupSelectBoxes() {
			this.groups = [];
			this.stats = [];
			this.views = [];

			const allOptions = [
				// Groups
				{ type: "groups", name: "Consultores", value: "consultant" },
				{
					type: "groups",
					name: "Equipas",
					value: "team",
					disabled: ["productivity", "percentages"].includes(this.view),
				},

				// Stats
				{ type: "stats", name: "Faturação", value: "billing" },
				{
					type: "stats",
					name: "Pago",
					value: "billingPaid",
					disabled: ["goals", "otherStats"].includes(this.view),
				},
				{
					type: "stats",
					name: "Previsto",
					value: "predicted",
					disabled: ["goals", "comparativeStats", "otherStats"].includes(this.view),
				},
				{ type: "stats", name: "Pipeline", value: "reservations", disabled: ["otherStats"].includes(this.view) },
				{
					type: "stats",
					name: "Desistências",
					value: "giveUps",
					disabled: ["goals", "comparativeStats", "otherStats"].includes(this.view),
				},
				{
					type: "stats",
					name: "Financiamento",
					value: "creditValue",
					disabled: ["goals", "otherStats"].includes(this.view),
				},
				{ type: "stats", name: "Angariações", value: "findings", disabled: ["otherStats"].includes(this.view) },
				// Views
				{ type: "views", name: "Por mês", value: "months" },
				{ type: "views", name: "Comparação por período", value: "comparison" },
				{ type: "views", name: "Objetivos", value: "goals" },
				{ type: "views", name: "Estatísticas Comparativas", value: "comparativeStats" },
				{ type: "views", name: "Outras Estatísticas", value: "otherStats" },
				{ type: "views", name: "Mapa de Produtividade", value: "productivity" },
			];

			if (!["commercialDirector", "teamLeader"].includes(this.user.role)) {
				allOptions.push({ type: "groups", name: "Diretores Comerciais", value: "commercialDirector" });
				allOptions.push({
					type: "groups",
					name: "Brokers",
					value: "broker",
					disabled: ["productivity", "percentages"].includes(this.view),
				});
				allOptions.push({
					type: "groups",
					name: "COO",
					value: "coo",
					disabled: ["productivity", "percentages"].includes(this.view),
				});
				allOptions.push({
					type: "groups",
					name: "Gestoras de Crédito",
					value: "creditManager",
					disabled: ["productivity", "percentages"].includes(this.view),
				});
				allOptions.push({
					type: "groups",
					name: "Agências",
					value: "office",
					disabled: ["productivity", "percentages"].includes(this.view),
				});
			}

			if (this.features.editCommissions) {
				allOptions.push({
					type: "stats",
					name: "Comissões",
					value: "commissions",
					disabled: ["goals", "otherStats"].includes(this.view),
				});
			}

			if (this.user.role === "cfo") allOptions.push({ type: "views", name: "Percentagens", value: "percentages" });

			if (this.features.listExpenses) {
				allOptions.push({
					type: "groups",
					name: "Sub-Categorias",
					value: "subCategory",
					disabled: ["otherStats", "goals", "productivity", "percentages"].includes(this.view),
				});
				allOptions.push({
					type: "stats",
					name: "Despesas",
					value: "expenses",
					disabled: ["otherStats", "goals", "productivity", "percentages"].includes(this.view),
				});
				allOptions.push({
					type: "stats",
					name: "Margem",
					value: "margin",
					disabled: ["otherStats", "goals", "productivity", "percentages"].includes(this.view),
				});
			}

			for (const field of this.fields && this.fields.length ? this.fields : allOptions.map(o => o.value)) {
				const option = allOptions.find(o => o.value === field);

				if (option) this[option.type].push(option);
			}

			if (!this.groups.find(g => g.value === this.groupBy)) this.groupBy = this.groups[0].value;
			if (!this.stats.find(s => s.value === this.stat)) this.stat = this.stats[0].value;
			if (!this.views.find(v => v.value === this.view)) this.view = this.views[0].value;
		},
		async handleGetCommercialDirectors() {
			const response = await getUsers(0, 10000, "name", false, {
				role: ["commercialDirector", "broker", "coo", "teamLeader"],
				all: true,
			});

			if (response.status === 200) {
				this.commercialDirectors = response.data.users;
			}
		},
		async handleGetIndividualStats() {
			this.loading = true;

			const response = await getGroupedStats(
				this.groupBy,
				this.stat,
				null,
				this.refFilter,
				this.activeFilter,
				this.roleFilter,
				this.officesFilter,
				this.commercialDirectorsFilter,
				this.teamsFilter,
				this.billingMinFilter,
				this.billingMaxFilter,
				this.commissionsMinFilter,
				this.commissionsMaxFilter,
				this.fromAllOffices ? this.allOffices.map(o => o.key) : null,
			);

			if (response.status === 200) {
				this.individualStats = [];
				for (const id in response.data) {
					const individual = response.data[id];

					if (id === "total") {
						this.total = individual;
					} else {
						this.individualStats.push(individual);
					}
				}

				this.individualStats.sort(
					(a, b) =>
						(a[this.sortBy] ? a[this.sortBy][this.stat] : 0) - (b[this.sortBy] ? b[this.sortBy][this.stat] : 0) ||
						(a[this.sortBy] ? a[this.sortBy][`${this.stat}Value`] : 0) -
							(b[this.sortBy] ? b[this.sortBy][`${this.stat}Value`] : 0),
				);
			}

			this.formatHeaders();

			this.loading = false;
		},
		getIconParameters(percentDiff) {
			return this.type === 2
				? { icon: "", color: "" }
				: percentDiff === undefined
				? { icon: "mdi-null", color: "grey" }
				: percentDiff === 0
				? { icon: "mdi-equal", color: "primary" }
				: percentDiff > 0
				? { icon: "mdi-arrow-up", color: "success" }
				: { icon: "mdi-arrow-down", color: "error" };
		},
		calculateColor(value, period, type) {
			const number = value ? value[type] : 0;

			// TODO: average or median?
			const ratio = number / (this.total[period] ? this.total[period].averageBilling : 0);

			const color = this.scale(ratio);

			return { color: this.showComparativeStats ? "" : color };
		},
		copyTable() {
			let lines = "";

			const periods = this.view === "comparison" ? ["lastPeriod", "period"] : this.months;

			for (const consultant of this.individualStats) {
				lines += "<tr>";

				lines += `<td>${consultant.name}</td>`;
				if (this.groupBy === "consultant") lines += `<td>${formatDate(consultant.startDate, "DD-MM-YYYY")}</td>`;

				for (const key of periods) {
					const fullKey = this.view === "comparison" ? key : `${key}-${this.year}`;
					const monthYear = consultant[fullKey];

					lines += `<td align="right">
						<span style="color: ${this.calculateColor(monthYear, fullKey, this.stat).color}">
							${formatNumber(this.showBilling && monthYear ? monthYear[this.stat] : 0, false, true)}
						</span>
						<br />
						${formatNumber(this.showCommissions && monthYear ? monthYear.commissions : 0, false, true)}
					</td>`;
				}

				if (this.view === "months") {
					lines += `<td align="right">
						<span style="color: ${this.calculateColor(consultant.period, "period", this.stat).color}">
							${formatNumber(this.showBilling && consultant.period ? consultant.period[this.stat] : 0, false, true)}
						</span>
						<br />
						${formatNumber(this.showCommissions && consultant.period ? consultant.period.commissions : 0, false, true)}
					</td>`;
				}

				lines += "</tr>";
			}

			lines += "<tr>";

			lines += `<td><b>Total</b></td>${this.groupBy === "commercialDirector" ? "" : "<td />"}`;

			for (const key of periods) {
				const fullKey = this.view === "comparison" ? key : `${key}-${this.year}`;
				const monthYear = this.total[fullKey];

				lines += `<td align="right">
					<b>
						<span style="color: ${this.calculateColor(monthYear, fullKey, this.stat).color}">
							${formatNumber(this.showBilling && monthYear ? monthYear[this.stat] : 0, false, true)}
						</span>
						<br />
						${formatNumber(this.showCommissions && monthYear ? monthYear.commissions : 0, false, true)}
					</b>
				</td>`;
			}

			if (this.view === "months") {
				lines += `<td align="right">
					<b>
						<span style="color: ${this.calculateColor(this.total.period, "period", this.stat).color}">
							${formatNumber(this.showBilling && this.total.period ? this.total.period[this.stat] : 0, false, true)}
						</span>
						<br />
						${formatNumber(this.showCommissions && this.total.period ? this.total.period.commissions : 0, false, true)}
					</b>
				</td>`;
			}

			lines += "</tr>";

			let monthTitles = "";

			if (this.view === "months") {
				for (const month of this.months) {
					monthTitles += `<th>${formatDate(`${this.year}-${month}-1`, "MMMM")}</th>`;
				}

				monthTitles += "<th>Total</th>";
			} else {
				monthTitles = `<th>${formatDate(
					`${this.firstMonth}-${this.year - 1}`,
					"MMM-YY",
					false,
					"M-YYYY",
				)} ➔ ${formatDate(`${this.lastMonth}-${this.year - 1}`, "MMM-YY", false, "M-YYYY")}</th>
				<th>${formatDate(`${this.firstMonth}-${this.year}`, "MMM-YY", false, "M-YYYY")} ➔ ${formatDate(
					`${this.lastMonth}-${this.year}`,
					"MMM-YY",
					false,
					"M-YYYY",
				)}</th>`;
			}

			copy(
				`<style>
					table, th, td { border: 1px solid black; border-collapse: collapse; font-size: 0.9em }
				</style>
				<table>
					<tr>
						<th>${this.groupBy === "commercialDirector" ? "Diretor Comercial" : "Consultor"}</th>
						${this.groupBy === "commercialDirector" ? "" : "<th>Data de Entrada</th>"}
						${monthTitles}
					</tr>
					${lines}
				</table>`,
				{ asHtml: true, format: "text/html" },
			);
		},
		changeAllCommissions(type, commission) {
			for (const consultant of this.individualStats) {
				const _id = consultant._id;

				const old = consultant[type];
				consultant[type] = commission;

				this.commissionsEdit[_id] = this.commissionsEdit[_id]
					? { ...this.commissionsEdit[_id], [type]: commission, [`${type}__old`]: old }
					: { [type]: commission, [`${type}__old`]: old };
			}

			this.editLength = Object.keys(this.commissionsEdit).length;
		},
		changeCommission(item, type, commission) {
			this.commissionsEdit[item._id] = this.commissionsEdit[item._id]
				? { ...this.commissionsEdit[item._id], [type]: commission, [`${type}__old`]: item[type] }
				: { [type]: commission, [`${type}__old`]: item[type] };
			this.editLength = Object.keys(this.commissionsEdit).length;
		},
		async handleBatchEdit() {
			this.editLoading = true;

			let edit = {};

			if (this.view === "productivity") {
				for (const _id in this.productivityEdit) {
					const productivity = this.individualStats
						.find(i => i._id === _id)
						.productivity.filter(m => !(m.month === this.lastMonth && m.year === this.year));

					edit[_id] = { productivity: [...productivity, this.productivityEdit[_id]] };
				}
			} else {
				edit = this.commissionsEdit;
			}

			const response = await patchUsers(edit);

			if (response.status === 200) {
				await this.handleGetIndividualStats();

				this.openToast({ message: "Consultores editados", color: "success" });
				this.commissionsEdit = {};
				this.productivityEdit = {};
				this.editLength = 0;
			}

			this.editLoading = false;
		},
		openGoalDetail(item) {
			if (item.goals?.length) {
				this.selectedGoals = item.goals.filter(g => g.type === this.stat);
			} else {
				this.selectedGoals = null;
			}

			this.selectedTarget = this.groupBy === "office" ? item._id : item;

			this.showGoalDetail = true;
		},
		closeGoalDetail() {
			this.showGoalDetail = false;

			this.handleGetIndividualStats();
		},
		openComments(item) {
			this.selectedTarget = item;

			this.showComments = true;
		},
		async handleDeleteGoal(item) {
			this.loading = true;

			const goal = item.goals.find(g => g.type === this.stat && g.frequency === "yearly");

			const response = await deleteGoal(goal._id);

			if (response.status === 200) {
				this.handleGetIndividualStats();

				this.loading = false;
			}
		},
		async onCommentSubmit(increment) {
			this.selectedTarget.comments += increment;
		},
		getProductivityKey(item, key) {
			const month = item.productivity.find(m => m.month === this.lastMonth && m.year === this.year);

			return month ? month[key] : false;
		},
		changeProductivity(item, key, value) {
			let month = item.productivity.find(m => m.month === this.lastMonth && m.year === this.year);

			this.productivityEdit[item._id] = this.productivityEdit[item._id]
				? { ...this.productivityEdit[item._id], [key]: value }
				: month
				? { ...month, [key]: value }
				: { month: this.lastMonth, year: this.year, [key]: value };
			this.editLength = Object.keys(this.productivityEdit).length;

			this.headers = this.headers.filter(h => h.value !== "");
			this.headers.push({ text: "", value: "", width: "100%" });
		},
		calculateProductivity(item) {
			let productivity = 0;
			const month = this.productivityEdit[item._id]
				? this.productivityEdit[item._id]
				: item.productivity.find(m => m.month === this.lastMonth && m.year === this.year);

			if (month) {
				if (month.hasReservation) productivity++;
				if (month.hasTransaction) productivity++;
				if (month.isFinder) productivity++;
				if (month.hasReference) productivity++;
			}

			return productivity / 4;
		},
		calculateKeyProductivity(key) {
			let productivity = 0;

			for (const individual of this.individualStats) {
				const month = this.productivityEdit[individual._id]
					? this.productivityEdit[individual._id]
					: individual.productivity.find(m => m.month === this.lastMonth && m.year === this.year);

				if (month && month[key]) productivity++;
			}

			return productivity / this.individualStats.length;
		},
		calculateTotalProductivity() {
			let productivity = 0;

			for (const individual of this.individualStats) {
				const month = this.productivityEdit[individual._id]
					? this.productivityEdit[individual._id]
					: individual.productivity.find(m => m.month === this.lastMonth && m.year === this.year);

				if (month) {
					if (month.hasReservation || month.hasTransaction || month.isFinder || month.hasReference) productivity++;
				}
			}

			return productivity / this.individualStats.length;
		},
	},
});
</script>
