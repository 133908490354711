<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleAddInvoice">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; max-width: 400px">
					<v-select
						outlined
						v-model="office"
						:items="offices"
						item-text="name"
						item-value="key"
						label="Agência"
						:rules="[required]"
					/>
					<v-text-field outlined v-model="ref" label="Referência" :rules="[required]" />
					<v-text-field outlined v-model="number" label="Número" type="number" :rules="[required]" />
					<v-menu
						v-model="showIssueDatePicker"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="issueDate"
								label="Data de Emissão"
								v-bind="attrs"
								v-on="on"
								:rules="[required]"
							/>
						</template>
						<v-date-picker v-model="date" color="primary" @input="showIssueDatePicker = false" />
					</v-menu>
					<v-menu v-model="showDatePicker" :close-on-content-click="false" transition="scale-transition" offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="date"
								label="Data de Report"
								v-bind="attrs"
								v-on="on"
								:rules="[required]"
							/>
						</template>
						<v-date-picker v-model="date" color="primary" @input="showDatePicker = false" />
					</v-menu>
					<v-text-field outlined v-model="owner" label="Proprietário" :rules="[required]" />
					<v-text-field outlined v-model="buyer" label="Comprador" :rules="[required]" />
					<v-autocomplete
						v-if="ref === 'Bank'"
						outlined
						v-model="finder"
						item-text="name"
						label="Bancos"
						:items="config.banks"
						return-object
						:rules="[required]"
					/>
					<div v-else style="display: flex">
						<v-combobox
							outlined
							v-model="finder"
							item-text="name"
							label="Angariador"
							:items="consultants"
							:rules="[required]"
							@change="
								newVal => {
									setExternal('finder');
									updateConsultantInfo('finder', newVal);
								}
							"
						/>
						<v-btn
							v-if="features.editCommissions"
							icon
							@click="() => updateConsultantInfo('finder', finder)"
							style="top: 10px"
						>
							<v-icon>mdi-refresh</v-icon>
						</v-btn>
						<v-checkbox
							v-model="isExternalFinder"
							hint="Externo"
							persistent-hint
							style="top: -5px; left: 10px; position: relative; min-width: 45px"
							@change="() => resetFinderSeller(true)"
						/>
					</div>
					<v-text-field
						v-if="!isExternalFinder"
						outlined
						append-icon="mdi-percent"
						v-model="finderPercentage"
						label="Percentagem do Angariador"
						type="number"
					/>
					<v-autocomplete
						v-if="!isExternalFinder"
						outlined
						v-model="finderCommercialDirector"
						item-text="name"
						item-value="_id"
						label="DC do Angariador"
						:items="managers"
					/>
					<v-text-field
						v-if="!isExternalFinder"
						outlined
						append-icon="mdi-percent"
						v-model="finderCommercialDirectorCommission"
						label="Percentagem do DC do Angariador"
						type="number"
					/>
					<v-text-field
						v-if="!isExternalFinder"
						outlined
						append-icon="mdi-percent"
						v-model="finderBrokerCommission"
						label="Percentagem do Broker do Angariador"
						type="number"
					/>
					<v-text-field
						v-if="!isExternalFinder"
						outlined
						append-icon="mdi-percent"
						v-model="finderCooCommission"
						label="Percentagem do COO do Angariador"
						type="number"
					/>
					<v-autocomplete
						v-if="!isExternalFinder"
						outlined
						v-model="finderRecruiter"
						item-text="name"
						item-value="_id"
						label="Recrutador do Angariador"
						:items="managers"
					/>
					<v-text-field
						v-if="!isExternalFinder"
						outlined
						append-icon="mdi-percent"
						v-model="finderRecruiterPercentage"
						label="Percentagem do Recrutador do Angariador"
						type="number"
					/>
					<v-autocomplete
						v-if="company === 'realtyart' && !isExternalFinder"
						outlined
						v-model="finderRecruiterDirector"
						item-text="name"
						item-value="_id"
						label="Diretor de Recrutamento do Angariador"
						:items="managers"
					/>
					<v-text-field
						v-if="company === 'realtyart' && !isExternalFinder"
						outlined
						append-icon="mdi-percent"
						v-model="finderRecruiterDirectorPercentage"
						label="Percentagem do Diretor de Recrutamento do Angariador"
						type="number"
					/>
					<div style="display: flex">
						<v-combobox
							outlined
							v-model="seller"
							item-text="name"
							label="Vendedor"
							:items="consultants"
							:rules="[required]"
							@change="
								newVal => {
									setExternal('seller');
									updateConsultantInfo('seller', newVal);
								}
							"
						/>
						<v-btn
							v-if="features.editCommissions"
							icon
							@click="() => updateConsultantInfo('seller', seller)"
							style="top: 10px"
						>
							<v-icon>mdi-refresh</v-icon>
						</v-btn>
						<v-checkbox
							v-model="isExternalSeller"
							hint="Externo"
							persistent-hint
							style="top: -5px; left: 10px; position: relative; min-width: 45px"
							@change="() => resetFinderSeller(true)"
						/>
					</div>
					<v-text-field
						v-if="!isExternalSeller"
						outlined
						append-icon="mdi-percent"
						v-model="sellerPercentage"
						label="Percentagem do Vendedor"
						type="number"
					/>
					<v-autocomplete
						v-if="!isExternalSeller"
						outlined
						v-model="sellerCommercialDirector"
						item-text="name"
						item-value="_id"
						label="DC do Vendedor"
						:items="managers"
					/>
					<v-text-field
						v-if="!isExternalSeller"
						outlined
						append-icon="mdi-percent"
						v-model="sellerCommercialDirectorCommission"
						label="Percentagem do DC do Vendedor"
						type="number"
					/>
					<v-text-field
						v-if="!isExternalSeller"
						outlined
						append-icon="mdi-percent"
						v-model="sellerBrokerCommission"
						label="Percentagem do Broker do Vendedor"
						type="number"
					/>
					<v-text-field
						v-if="!isExternalSeller"
						outlined
						append-icon="mdi-percent"
						v-model="sellerCooCommission"
						label="Percentagem do COO do Vendedor"
						type="number"
					/>
					<v-autocomplete
						v-if="!isExternalSeller"
						outlined
						v-model="sellerRecruiter"
						item-text="name"
						item-value="_id"
						label="Recrutador do Vendedor"
						:items="managers"
					/>
					<v-text-field
						v-if="!isExternalSeller"
						outlined
						append-icon="mdi-percent"
						v-model="sellerRecruiterPercentage"
						label="Percentagem do Recrutador do Vendedor"
						type="number"
					/>
					<v-autocomplete
						v-if="company === 'realtyart' && !isExternalSeller"
						outlined
						v-model="sellerRecruiterDirector"
						item-text="name"
						item-value="_id"
						label="Diretor de Recrutamento do Angariador"
						:items="managers"
					/>
					<v-text-field
						v-if="company === 'realtyart' && !isExternalSeller"
						outlined
						append-icon="mdi-percent"
						v-model="sellerRecruiterDirectorPercentage"
						label="Percentagem do Diretor de Recrutamento do Vendedor"
						type="number"
					/>
					<v-text-field
						outlined
						append-icon="mdi-currency-eur"
						v-model="value"
						label="Comissão Total"
						type="number"
						:rules="[required]"
					/>
					<v-text-field
						v-if="isExternalSeller"
						outlined
						append-icon="mdi-percent"
						v-model="percentageSplit"
						label="Percentagem Interna"
						type="number"
					/>
					<v-autocomplete
						v-if="ref === 'Bank'"
						outlined
						v-model="creditManager"
						item-text="name"
						label="Gestora de Crédito"
						:items="creditManagers"
						return-object
					/>
					<v-text-field
						v-if="ref === 'Bank'"
						outlined
						append-icon="mdi-percent"
						v-model="creditManagerCommission"
						label="Percentagem da Gestora de Crédito"
						type="number"
					/>
					<v-textarea outlined v-model="observations" label="Observações" height="145px" hide-details />
				</v-col>
				<v-divider v-if="invoice" vertical />
				<v-col v-if="invoice" style="margin: 40px; max-width: 400px">
					<v-menu
						v-model="showClientProofDatePicker"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="clientProofDate"
								label="Data de Comprovativo do Cliente"
								v-bind="attrs"
								v-on="on"
							/>
						</template>
						<v-date-picker v-model="clientProofDate" color="primary" @input="showClientProofDatePicker = false" />
					</v-menu>
					<v-menu
						v-model="showClientPaymentDatePicker"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="clientPaymentDate"
								label="Data de Pagamento do Cliente"
								v-bind="attrs"
								v-on="on"
							/>
						</template>
						<v-date-picker
							v-model="clientPaymentDate"
							color="primary"
							@input="showClientPaymentDatePicker = false"
						/>
					</v-menu>
					<v-divider v-if="!isExternalFinder" />
					<v-menu
						v-if="!isExternalFinder"
						v-model="showFinderBillsDatePicker"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="sentBillsToFinder"
								label="Fatura enviada ao angariador"
								v-bind="attrs"
								v-on="on"
							/>
						</template>
						<v-date-picker
							v-model="sentBillsToFinder"
							color="primary"
							@input="showFinderBillsDatePicker = false"
						/>
					</v-menu>
					<v-menu
						v-if="!isExternalFinder && sentBillsToFinder"
						v-model="showFinderPaymentDatePicker"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="finderPaymentDate"
								label="Data de Pagamento ao Angariador"
								v-bind="attrs"
								v-on="on"
							/>
						</template>
						<v-date-picker
							v-model="finderPaymentDate"
							color="primary"
							@input="showFinderPaymentDatePicker = false"
						/>
					</v-menu>
					<v-divider />
					<v-menu
						v-if="!isExternalSeller"
						v-model="showSellerBillsDatePicker"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="sentBillsToSeller"
								label="Fatura enviada ao vendedor"
								v-bind="attrs"
								v-on="on"
							/>
						</template>
						<v-date-picker
							v-model="sentBillsToSeller"
							color="primary"
							@input="showSellerBillsDatePicker = false"
						/>
					</v-menu>
					<br v-if="isExternalSeller" />
					<v-menu
						v-if="isExternalSeller || sentBillsToSeller"
						v-model="showSellerPaymentDatePicker"
						:close-on-content-click="false"
						transition="scale-transition"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								outlined
								v-model="sellerPaymentDate"
								label="Data de Pagamento ao Vendedor"
								v-bind="attrs"
								v-on="on"
							/>
						</template>
						<v-date-picker
							v-model="sellerPaymentDate"
							color="primary"
							@input="showSellerPaymentDatePicker = false"
						/>
					</v-menu>
				</v-col>
			</v-row>
			<v-btn v-if="!invoice" @click="handleAddInvoice">Adicionar</v-btn>
			<v-btn v-if="invoice" @click="handleEditInvoice">Atualizar</v-btn>
			<br /><br />
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";

import { mapState, mapMutations } from "vuex";

import { getUsers } from "../api/users";
import { addInvoice, editInvoice } from "../api/invoices";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "InvoiceForm",
	props: ["invoice", "onClose"],
	created() {
		this.office = this.selectedOffices[0];

		this.handleGetUsers();

		if (this.invoice) {
			this.setInvoice(this.invoice);
		}
	},
	watch: {
		selectedOffices: function () {
			this.office = this.selectedOffices[0];
		},
		invoice: function (newVal) {
			if (newVal) {
				this.setInvoice(newVal);
			} else {
				this.office = this.selectedOffices[0];
				this.ref = "";
				this.number = null;
				this.date = formatDate(dayjs(), "YYYY-MM-DD");
				this.owner = "";
				this.buyer = "";
				this.finder = "";
				this.finderPercentage = null;
				this.finderTeam = null;
				this.finderCommercialDirector = null;
				this.finderCommercialDirectorCommission = null;
				this.finderBrokerCommission = null;
				this.finderCooCommission = null;
				this.finderRecruiter = null;
				this.finderRecruiterPercentage = null;
				this.finderRecruiterDirector = null;
				this.finderRecruiterDirectorPercentage = null;
				this.sentBillsToFinder = null;
				this.finderPaymentDate = null;
				this.seller = "";
				this.sellerPercentage = null;
				this.sellerTeam = null;
				this.sellerCommercialDirector = null;
				this.sellerCommercialDirectorCommission = null;
				this.sellerBrokerCommission = null;
				this.sellerCooCommission = null;
				this.sellerRecruiter = null;
				this.sellerRecruiterPercentage = null;
				this.sellerRecruiterDirector = null;
				this.sellerRecruiterDirectorPercentage = null;
				this.sentBillsToSeller = null;
				this.sellerPaymentDate = null;
				this.value = null;
				this.clientPaymentDate = null;
				this.clientProofDate = null;
				this.creditManager = null;
				this.creditManagerCommission = null;
				this.observations = null;
			}
		},
		ref: function (newVal) {
			if (newVal === "Bank") {
				this.isExternalFinder = true;
				this.finderPercentage = null;
				this.finderTeam = null;
				this.finderCommercialDirector = null;
				this.finderCommercialDirectorCommission = null;
				this.finderBrokerCommission = null;
				this.finderCooCommission = null;
				this.finderRecruiter = null;
				this.finderRecruiterPercentage = null;
				this.finderRecruiterDirector = null;
				this.finderRecruiterDirectorPercentage = null;
			}
		},
	},
	computed: mapState(["company", "offices", "selectedOffices", "config", "features"]),
	data() {
		return {
			loading: false,

			consultants: [],
			managers: [],
			creditManagers: [],

			required: value => !!value || "Obrigatório",
			showDatePicker: false,
			showIssueDatePicker: false,
			showClientPaymentDatePicker: false,
			showClientProofDatePicker: false,
			showFinderPaymentDatePicker: false,
			showSellerPaymentDatePicker: false,
			showFinderBillsDatePicker: false,
			showSellerBillsDatePicker: false,

			office: null,
			ref: "",
			number: null,
			issueDate: formatDate(dayjs(), "YYYY-MM-DD"),
			date: formatDate(dayjs(), "YYYY-MM-DD"),
			owner: "",
			buyer: "",
			finder: "",
			isExternalFinder: true,
			finderPercentage: null,
			finderTeam: null,
			finderCommercialDirector: null,
			finderCommercialDirectorCommission: null,
			finderBrokerCommission: null,
			finderCooCommission: null,
			finderRecruiter: null,
			finderRecruiterPercentage: null,
			finderRecruiterDirector: null,
			finderRecruiterDirectorPercentage: null,
			sentBillsToFinder: null,
			finderPaymentDate: null,
			seller: "",
			isExternalSeller: true,
			sellerPercentage: null,
			sellerTeam: null,
			sellerCommercialDirector: null,
			sellerCommercialDirectorCommission: null,
			sellerBrokerCommission: null,
			sellerCooCommission: null,
			sellerRecruiter: null,
			sellerRecruiterPercentage: null,
			sellerRecruiterDirector: null,
			sellerRecruiterDirectorPercentage: null,
			percentageSplit: null,
			sentBillsToSeller: null,
			sellerPaymentDate: null,
			value: null,
			clientPaymentDate: null,
			clientProofDate: null,
			creditManager: null,
			creditManagerCommission: null,
			observations: "",
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		setInvoice(newVal) {
			this.office = newVal.office;
			this.ref = newVal.ref;
			this.number = newVal.number;
			this.issueDate = formatDate(newVal.issueDate, "YYYY-MM-DD");
			this.date = formatDate(newVal.date, "YYYY-MM-DD");
			this.owner = newVal.owner;
			this.buyer = newVal.buyer;
			this.finder = this.invoice.finder ? this.invoice.finder : "";
			this.isExternalFinder = newVal.isExternalFinder;
			this.finderPercentage = newVal.finderPercentage;
			this.finderTeam = newVal.finderTeam;
			this.finderCommercialDirector = newVal.finderCommercialDirector;
			this.finderCommercialDirectorCommission = newVal.finderCommercialDirectorCommission;
			this.finderBrokerCommission = newVal.finderBrokerCommission;
			this.finderCooCommission = newVal.finderCooCommission;
			this.finderRecruiter = newVal.finderRecruiter;
			this.finderRecruiterPercentage = newVal.finderRecruiterPercentage;
			this.finderRecruiterDirector = newVal.finderRecruiterDirector;
			this.finderRecruiterDirectorPercentage = newVal.finderRecruiterDirectorPercentage;
			this.sentBillsToFinder = newVal.sentBillsToFinder && formatDate(newVal.sentBillsToFinder, "YYYY-MM-DD");
			this.finderPaymentDate = newVal.finderPaymentDate && formatDate(newVal.finderPaymentDate, "YYYY-MM-DD");
			this.seller = this.invoice.seller ? this.invoice.seller : "";
			this.isExternalSeller = newVal.isExternalSeller;
			this.sellerPercentage = newVal.sellerPercentage;
			this.sellerTeam = newVal.sellerTeam;
			this.sellerCommercialDirector = newVal.sellerCommercialDirector;
			this.sellerCommercialDirectorCommission = newVal.sellerCommercialDirectorCommission;
			this.sellerBrokerCommission = newVal.sellerBrokerCommission;
			this.sellerCooCommission = newVal.sellerCooCommission;
			this.sellerRecruiter = newVal.sellerRecruiter;
			this.sellerRecruiterPercentage = newVal.sellerRecruiterPercentage;
			this.sellerRecruiterDirector = newVal.sellerRecruiterDirector;
			this.sellerRecruiterDirectorPercentage = newVal.sellerRecruiterDirectorPercentage;
			this.percentageSplit = newVal.percentageSplit ? newVal.percentageSplit * 100 : null;
			this.sentBillsToSeller = newVal.sentBillsToSeller && formatDate(newVal.sentBillsToSeller, "YYYY-MM-DD");
			this.sellerPaymentDate = newVal.sellerPaymentDate && formatDate(newVal.sellerPaymentDate, "YYYY-MM-DD");
			this.value = newVal.value;
			this.clientPaymentDate = newVal.clientPaymentDate && formatDate(newVal.clientPaymentDate, "YYYY-MM-DD");
			this.clientProofDate = newVal.clientProofDate && formatDate(newVal.clientProofDate, "YYYY-MM-DD");
			this.creditManager = this.invoice.creditManager ? this.invoice.creditManager : "";
			this.creditManagerCommission = newVal.creditManagerCommission;
			this.observations = newVal.observations;
		},
		setExternal(type) {
			this[`isExternal${type.charAt(0).toUpperCase() + type.slice(1)}`] = !this[type]?._id;
		},
		updateConsultantInfo(type, newVal) {
			this[`isExternal${type.charAt(0).toUpperCase() + type.slice(1)}`] =
				!newVal?._id || (type === "finder" && this.ref === "Bank");
			this[`${type}Percentage`] =
				type === "seller" && this.ref === "Bank" && newVal?.bankCommission
					? newVal?.bankCommission
					: newVal?.commission || null;
			this[`${type}Team`] = newVal?.team || null;
			this[`${type}CommercialDirector`] = newVal?.manager || null;
			this[`${type}CommercialDirectorCommission`] =
				type === "seller" && this.ref === "Bank" && newVal?.commercialDirectorBankCommission
					? newVal?.commercialDirectorBankCommission
					: newVal?.commercialDirectorCommission || null;
			this[`${type}BrokerCommission`] = newVal?.brokerCommission || null;
			this[`${type}CooCommission`] = newVal?.cooCommission || null;
			this[`${type}Recruiter`] = newVal?.recruiter || null;
			this[`${type}RecruiterPercentage`] = newVal?.recruiterCommission || null;
			this[`${type}RecruiterDirector`] = newVal?.recruiterDirector || null;
			this[`${type}RecruiterDirectorPercentage`] = newVal?.recruiterDirectorCommission || null;

			if (type === "seller") this.creditManagerCommission = newVal?.creditManagerCommission || null;
		},
		resetFinderSeller(finder) {
			if (finder) this.finder = "";
			else this.seller = "";
		},
		isFormValid() {
			if (!this.$refs.form.validate()) return false;

			return true;
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { all: true, infoLevel: 2 });

			if (response.status === 200) {
				this.consultants = response.data.users;
				this.managers = response.data.users.filter(
					u =>
						u.active &&
						[
							"ceo",
							"cfo",
							"coo",
							"broker",
							"commercialDirector",
							"teamLeader",
							"recruiter",
							"expansionDirector",
							"integration",
						].includes(u.role),
				);
				this.creditManagers = response.data.users.filter(
					u => u.active && ["creditManager", "teamLeader"].includes(u.role),
				);
			}
		},
		async handleAddInvoice() {
			this.loading = true;
			const {
				office,
				ref,
				number,
				issueDate,
				date,
				owner,
				buyer,
				finder,
				finderPercentage,
				finderTeam,
				finderCommercialDirector,
				finderCommercialDirectorCommission,
				finderBrokerCommission,
				finderCooCommission,
				finderRecruiter,
				finderRecruiterPercentage,
				finderRecruiterDirector,
				finderRecruiterDirectorPercentage,
				seller,
				sellerPercentage,
				sellerTeam,
				sellerCommercialDirector,
				sellerCommercialDirectorCommission,
				sellerBrokerCommission,
				sellerCooCommission,
				sellerRecruiter,
				sellerRecruiterPercentage,
				sellerRecruiterDirector,
				sellerRecruiterDirectorPercentage,
				value,
				creditManager,
				creditManagerCommission,
				observations,
			} = this;

			if (!this.isFormValid()) return;

			const response = await addInvoice({
				office,
				ref,
				number,
				issueDate,
				date,
				owner,
				buyer,
				finder: finder.key || finder._id || finder,
				isExternalFinder: !finder._id || ref === "Bank",
				finderPercentage,
				finderTeam,
				finderCommercialDirector,
				finderCommercialDirectorCommission,
				finderBrokerCommission,
				finderCooCommission,
				finderRecruiter,
				finderRecruiterPercentage,
				finderRecruiterDirector,
				finderRecruiterDirectorPercentage,
				seller: seller._id || seller,
				isExternalSeller: !seller._id,
				sellerPercentage,
				sellerTeam,
				sellerCommercialDirector,
				sellerCommercialDirectorCommission,
				sellerBrokerCommission,
				sellerCooCommission,
				sellerRecruiter,
				sellerRecruiterPercentage,
				sellerRecruiterDirector,
				sellerRecruiterDirectorPercentage,
				value,
				creditManager,
				creditManagerCommission,
				observations,
			});

			if (response.status === 201) {
				this.onClose();
			} else if (response.status === 400) {
				this.openToast({ message: "Escolha uma agência", color: "red" });
			}

			this.loading = false;
		},
		async handleEditInvoice() {
			this.loading = true;
			const {
				office,
				ref,
				number,
				issueDate,
				date,
				owner,
				buyer,
				finder,
				finderPercentage,
				finderTeam,
				finderCommercialDirector,
				finderCommercialDirectorCommission,
				finderBrokerCommission,
				finderCooCommission,
				finderRecruiter,
				finderRecruiterPercentage,
				finderRecruiterDirector,
				finderRecruiterDirectorPercentage,
				sentBillsToFinder,
				finderPaymentDate,
				seller,
				sellerPercentage,
				sellerTeam,
				sellerCommercialDirector,
				sellerCommercialDirectorCommission,
				sellerBrokerCommission,
				sellerCooCommission,
				sellerRecruiter,
				sellerRecruiterPercentage,
				sellerRecruiterDirector,
				sellerRecruiterDirectorPercentage,
				percentageSplit,
				sentBillsToSeller,
				sellerPaymentDate,
				value,
				clientPaymentDate,
				clientProofDate,
				creditManager,
				creditManagerCommission,
				observations,
			} = this;

			if (!this.isFormValid()) return;

			const response = await editInvoice({
				_id: this.invoice._id,
				office,
				ref,
				number,
				issueDate,
				date,
				owner,
				buyer,
				finder: finder.key || finder._id || finder.name || finder,
				isExternalFinder: !finder._id || ref === "Bank",
				finderPercentage,
				finderTeam,
				finderCommercialDirector,
				finderCommercialDirectorCommission,
				finderBrokerCommission,
				finderCooCommission,
				finderRecruiter,
				finderRecruiterPercentage,
				finderRecruiterDirector,
				finderRecruiterDirectorPercentage,
				sentBillsToFinder,
				finderPaymentDate: sentBillsToFinder ? finderPaymentDate : null,
				seller: seller._id || seller.name || seller,
				isExternalSeller: !seller._id,
				percentageSplit,
				sellerPercentage,
				sellerTeam,
				sellerCommercialDirector,
				sellerCommercialDirectorCommission,
				sellerBrokerCommission,
				sellerCooCommission,
				sellerRecruiter,
				sellerRecruiterPercentage,
				sellerRecruiterDirector,
				sellerRecruiterDirectorPercentage,
				sentBillsToSeller,
				sellerPaymentDate: (seller._id && sentBillsToSeller) || !seller._id ? sellerPaymentDate : null,
				value,
				clientPaymentDate,
				clientProofDate,
				creditManager,
				creditManagerCommission,
				observations,
			});

			if (response.status === 200) {
				this.onClose();
			}

			this.loading = false;
		},
	},
});
</script>
